import React from "react";
import "./Home.css";

const Home = () => {
  return (
    <div className="home">
      <h2>Welcome to the Home Page</h2>
    </div>
  );
};

export default Home;