import React from "react";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import NavButton from "./NavButton";
import "./Navbar.css";

const Navbar = () => {
  const { user, signIn, signOut } = useAuth();

  return (
    <nav className="navbar">
      <h1>DH App</h1>
      <div className="nav-buttons">
        <Link to="/">Home</Link>
        <Link to="/dashboard">Dashboard</Link>
        {!user ? (
          <NavButton onClick={signIn} label="Login" />
        ) : (
          <NavButton onClick={signOut} label="Logout" />
        )}
      </div>
    </nav>
  );
};

export default Navbar;