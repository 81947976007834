import React, { useEffect, useState } from "react";
import apiClient from "../../api/apiClient";
import Card from "../../components/Card/Card";
import "./Dashboard.css";

const Dashboard = () => {
  const [noteCount, setNoteCount] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNoteCount = async () => {
      try {
        const { data } = await apiClient.get("/api/notes/count");
        setNoteCount(data.count);
      } catch (err) {
        setError(err.response?.data?.message || err.message);
      }
    };

    fetchNoteCount();
  }, []);

  return (
    <div className="dashboard">
      <h2>Dashboard</h2>
      {error ? (
        <div className="error-message">Failed to load data: {error}</div>
      ) : noteCount !== null ? (
        <Card title="Notes Count" content={noteCount} />
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default Dashboard;