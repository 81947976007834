import React from "react";
import "./Navbar.css";

const NavButton = ({ onClick, label }) => {
  return (
    <button onClick={onClick} className="nav-button">
      {label}
    </button>
  );
};

export default NavButton;